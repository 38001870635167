
import { ShareholderMovement } from "@/services/movements/types";
import { defineStore } from "pinia";
import { MovementService } from "@/services/movements/MovementService";

export const shareMovementStore = defineStore("movements", {
    state: () => {
        const movements : ShareholderMovement[]  = [];
        const shareholderShareService = new MovementService();
        const promise: Promise<Response> = new Promise(res=>(res));
        const filter: object = {
            start_date: '',
            end_date: '',
        };

        return {
            movements : movements ,
            shareholderShareService,
            promise,
            filter
        }
    },

    actions: {
        async query(id : number){
             await this.shareholderShareService
               .getShareholderMovement(id, this.filter)
               .then(({data})=> (this.movements = data) ); 
        },
        setFilter(filter : object){
            this.filter = filter;
        }
    },

    getters: {
        getData(state){
            return state.movements 
        },
        getFilter(state){
            return state.filter
        }
    }
})

export type AuthStore = ReturnType<typeof shareMovementStore>;