import { ref } from 'vue';
import { defineStore } from "pinia";

import { InvestmentService } from '@/services/invest/investmentService';
import { Investment } from '@/services/invest/types';
import { ResponsePagination } from '@/services/types';

export const useInvestmentStore = defineStore('investmentStore', () => {
    const investmentService = new InvestmentService();
    const investments = ref<Investment[]>();
    const fetchResponse = ref<ResponsePagination<Investment[]>>();
    const pagination = ref<any>({
        page:1,
        per_page:undefined,
        total_pages:undefined,
        //total_record:undefined,
    })

    const filters = ref<any>({
        type:'',
        status:'',
    })

    const fetchInvestments = async () => {
        fetchResponse.value = await investmentService.getInvestiments(filters.value.status, filters.value.type,pagination.value.page);

        if(fetchResponse.value?.success){
            investments.value = fetchResponse.value?.data;
            pagination.value = {
                page: fetchResponse.value.page,
                per_page: fetchResponse.value.per_page,
                total_pages: fetchResponse.value.total_pages,
            }
        }
    }

    return {
        investments,
        pagination,
        fetchResponse,
        filters,

        fetchInvestments,
    }
});

export default useInvestmentStore;