import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake, getResponsePagination } from "../utils";

import { reformatDateISO8601 } from "@/services/utils";

import { Response, ResponsePagination } from "../types";
import {
  Investment,
  Record,
  EditInvest,
  InvestmentRequest,
  UploadInvest,
  InvestmentContractAlert,
  CreateInvestimentContractAlert,
} from "./types";

export class InvestmentService extends AuthenticatedService {
  async getInvestiments(
    investment_status: string,
    investment_type: string,
    page = 0
  ): Promise<ResponsePagination<Investment[]>> {
    let url = `${getEnvConfig().apiURL}/investments/all?`;

    if (page > 0) {
      url += `page=${page}`;
    }
    if (investment_status != "") {
      url += `&investment_status=${investment_status}`;
    }
    if (investment_type != "") {
      url += `&investment_type=${investment_type}`;
    }

    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      Investment
    );
  }
  async getRecords(id: number): Promise<Response<Record[]>> {
    const url =
      `${getEnvConfig().apiURL}/investments/records/all/` + id.toString();

    return getResponse(url, this.token, "GET", undefined, undefined, Record);
  }
  async getLastRecordDate(id: number): Promise<Response<Date>> {
    const url =
      `${getEnvConfig().apiURL}/investments/records/get_last_record_date/` +
      id?.toString();

    return getResponse(url, this.token, "GET", undefined, undefined);
  }
  async uploadImage(uploadImage: UploadInvest) {
    const formUploadContractData = new FormData();

    formUploadContractData.append("file", uploadImage.image);
    const url = `${
      getEnvConfig().apiURL
    }/investments/${uploadImage.id.toString()}/image `;

    return getResponse(
      url,
      this.token,
      "POST",
      undefined,
      formUploadContractData
    );
  }

  async createInvestiment(
    data: InvestmentRequest
  ): Promise<Response<Investment>> {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("investment_type", data.investmentType);
    formData.append("investment_status", data.investmentStatus);
    formData.append("value", data.value.toString());

    formData.append(
      "monthly_profitability_uf",
      data.monthlyProfitabilityUf ? data.monthlyProfitabilityUf.toString() : ""
    );
    formData.append(
      "monthly_profitability_clp",
      data.monthlyProfitabilityClp
        ? data.monthlyProfitabilityClp.toString()
        : ""
    );
    formData.append("thumbnail", data.thumbnail);
    formData.append("is_deleted", data.isDeleted.toString());

    const url = `${getEnvConfig().apiURL}/investments/create`;

    return getResponse(url, this.token, "POST", undefined, formData);
  }
  async createRecord(data: Record): Promise<Response<Record>> {
    const body = keysToSnake({
      title: data.title,
      description: data.description,
      investmentId: data.investmentId,
    });
    const url = `${getEnvConfig().apiURL}/investments/records/create`;

    return getResponse(url, this.token, "POST", body, undefined);
  }
  async editInvestiment(data: EditInvest): Promise<Response<EditInvest>> {
    const body = {
      id: data.id,
      title: data.title,
      description: data.description,
      value: data.value,
      monthly_profitability_uf: data.monthlyProfitabilityUf,
      monthly_profitability_clp: data.monthlyProfitabilityClp,
      investment_status: data.investmentStatus,
    };
    const url = `${getEnvConfig().apiURL}/investments/edit`;
    return getResponse(url, this.token, "POST", body, undefined);
  }

  async fetchEntitySettings(roleUser = "SHAREHOLDER", type = "Investment") {
    const url = `${
      getEnvConfig().apiURL
    }/auth/entity-settings?role_user${roleUser}&type=${type}`;
    const { data } = await getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined
    );

    const fieldArray = data.data[0].defaultSettings[0];

    const usedFields = data.data[0].info[0].fields.split(",");
    const id = data.data[0].info[0].id;

    console.log(id);

    let allFields: any = [];

    if (fieldArray != undefined && fieldArray.length > 0) {
      fieldArray.forEach((field: any) => {
        allFields = [
          ...allFields,
          {
            name: field.name,
            description:
              field.description == "id" ? "Identificador" : field.description,
            checked: usedFields.find((f: string) => f == field.name)
              ? true
              : false,
          },
        ];
      });
    }

    return { ...data, id: id, allFields };
  }

  async setEntitySettings(id: number, fields: any) {
    const url = `${getEnvConfig().apiURL}/auth/entity-settings/${id}`;
    let parsedFields: any = [];

    fields.forEach((field: any) => {
      if (field.checked == true) {
        parsedFields = [...parsedFields, field.name];
      }
    });

    return getResponse(
      url,
      this.token,
      "PUT",
      { fields: parsedFields },
      undefined
    );
  }

  // Investment Contract Alerts ==============

  async getInvestmentContractAlerts(
    contractId: number,
    page = 1
  ): Promise<ResponsePagination<InvestmentContractAlert[]>> {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract/${contractId}/alerts?page=${page}`;

    return getResponsePagination(url, this.token, "GET", undefined, undefined);
  }

  async getInvestmentContractAlert(
    alertId: number
  ): Promise<Response<InvestmentContractAlert>> {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract-alert/${alertId}`;

    return getResponse(url, this.token, "GET", undefined, undefined);
  }

  async createInvestmentContractAlert(
    contractId: number,
    form: CreateInvestimentContractAlert
  ): Promise<Response<void>> {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract-alert/${contractId}`;
    const formData = {
      description: form.description,
      shipment_date: form.shipmentDate
        ? reformatDateISO8601(form.shipmentDate, true, true)
        : "",
    };

    return getResponse(url, this.token, "POST", formData, undefined);
  }

  async editInvestmentContractAlert(
    alertId: number,
    form: CreateInvestimentContractAlert
  ): Promise<Response<void>> {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract-alert/${alertId}`;

    const formData = {
      description: form.description,
      shipment_date: form.shipmentDate
        ? reformatDateISO8601(form.shipmentDate, true, true)
        : "",
    };

    return getResponse(url, this.token, "PUT", formData, undefined);
  }

  async deleteInvestmentContractAlert(
    alertId: number
  ): Promise<Response<void>> {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract-alert/${alertId}`;

    return getResponse(url, this.token, "DELETE", undefined, undefined);
  }
}
