import { Account } from "@/services/account/types";
import { defineStore } from "pinia";
import { AccountService } from "@/services/account/AccountService";
import { Ref, ref } from "vue";

export const accountStore = defineStore("account", {
  state: () => {
    const account: Account = new Account()
    const activeAccount: Ref<number> = ref(0);
    const accountService = new AccountService();
    const promise: Promise<Response> = new Promise((res) => res);

    return {
      account,
      accountService,
      promise,
      activeAccount,
    };
  },

  actions: {
    async query(id: number) {
      await this.accountService
        .getAccountServices(id)
        .then(({ data }) => (this.account = data ));
      
    },
    async deleteAccount(id: number) {
      await this.accountService
        .getAccountServices(id)
        .then(({ data }) => (this.account = data ));
      
    },
    setActiveAccount(id:number){
      this.activeAccount = id; 
    }
  },

  getters: {
    getData(state) {
      return state.account;
    },
  },
});

export type AccountStore = ReturnType<typeof accountStore>;
