import { ref } from 'vue';
import { defineStore } from "pinia";
import { ContractService } from '@/services/contract/contractService';

export const useContractStore = defineStore('contractStore', () => {
    const contractService = new ContractService();
    const fetchResponse = ref<any>(null);
    const fetchContractResponse = ref<any>(null);
    const createResponse = ref<any>(null);
    const updateResponse = ref<any>(null);
    const deleteResponse = ref<any>(null);
    const contractList = ref<any>(null);
    const pagination = ref<any>({
        page:1,
        per_page:undefined,
        total_pages:undefined,
        total_record:undefined,
    });
    const filter = ref<any>({
        type:'',
        start_date:'',
        end_date: '',
        search:'',
    });

    const newContract = ref<any>({
        investment_id: '',
        type: '',
        start_date: undefined,
        end_date:undefined,
        thumbnail:'',
        comment:'',
        notify_indefinitely: false
    })

    const fetchedContract = ref<any>({
        investment_id: '',
        type: '',
        start_date:undefined,
        end_date:undefined,
        thumbnail:'',
        comment:'',
        notify_indefinitely : false,
    })

    const fetchContracts = async (investmentId: number) => {
        fetchResponse.value = await contractService.getInvestmentContracts(investmentId, filter.value, pagination.value.page);

        if(fetchResponse.value?.success && fetchResponse.value?.data){
            contractList.value = fetchResponse.value.data?.data;
            pagination.value = {
                page:fetchResponse.value.data.page,
                per_page:fetchResponse.value.data.perPage,
                total_pages:fetchResponse.value.data.totalPages,
                total_record:fetchResponse.value.data.totalRecord,
            }
        }
    }

    const fetchContract = async (contractId: number) => {
        fetchContractResponse.value = await contractService.getInvestmentContract(contractId);

        if(fetchContractResponse.value?.success){
            fetchedContract.value.investment_id = fetchContractResponse.value.data.investmentId;
            fetchedContract.value.type = fetchContractResponse.value.data.type;
            fetchedContract.value.start_date = new Date(fetchContractResponse.value.data.startDate);
            fetchedContract.value.end_date = fetchedContract.value.end_date != null  || fetchedContract.value.end_date != undefined ? new Date(fetchContractResponse.value.data.endDate) : undefined;
            fetchedContract.value.comment = fetchContractResponse.value.data.comment;
            fetchedContract.value.notify_indefinitely = fetchContractResponse.value.data.notifyIndefinitely;

            if(fetchedContract.value.type == 'PURCHASE_SALE'){      // sales have no end date so this will clear wrong contracts.
                fetchedContract.value.end_date = undefined;
            }
            if(fetchedContract.value.type == 'SALE'){      // sales have no end date so this will clear wrong contracts.
                fetchedContract.value.end_date = undefined;
            }
        }
    }

    const createContract = async () =>{
        createResponse.value = await contractService.createInvestmentContract(newContract.value);
    }

    const updateContract = async (contractId: number) => {
        updateResponse.value = await contractService.updateInvestmentContract(contractId,fetchedContract.value);
    }

    const deleteContract = async (contractId:number) => {
        deleteResponse.value = await contractService.deleteInvestmentContract(contractId);
    }

    const downloadContract = async (contractId:number) =>{
        await contractService.downloadInvestmentContract(contractId);
    }

    const getContractType = (type:string) =>{
        switch(type){
            case 'RENT':
                return 'Arriendo';
            case 'PURCHASE_SALE':
                return 'Compra';
            case 'RENT_PURCHASE_SALE':
                return 'Arriendo con opción de compra';
            case 'SALE':
                return 'Venta';
            default :
                return '-'
        }
    }

    const clearNewContract = () => {
        newContract.value = {
            type: '',
            start_date:undefined,
            end_date:undefined,
            thumbnail:'',
            comment:'',
            notify_indefinitely: false
        };
    }

    return {
        contractList,
        filter,
        newContract,
        fetchedContract,
        fetchResponse,
        fetchContractResponse,
        updateResponse,
        createResponse,
        deleteResponse,
        pagination,

        clearNewContract,
    
        fetchContracts,
        fetchContract,
        createContract,
        updateContract,
        deleteContract,
        downloadContract,
        getContractType,
        
    }
})

export default useContractStore;