import { getEnvConfig } from "@/configs/UrlConfig";
import {
  getResponse,
  keysToSnake,
  fetchDownloadFile,
  getResponsePagination,
} from "../utils";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Shareholder, ShareholderResume } from "./types";

export class ShareholderService extends AuthenticatedService {
  async getShareholders(
    page = 0,
    order = "",
    typeOrder = false,
    search = ""
  ): Promise<Response<ShareholderResume[]>> {
    let url = `${getEnvConfig().apiURL}/admin/user_accounts?`;
    if (page > 0) {
      url += `page=${page}`;
    }else{
      url += `without_pages=1`;
    }
    if (order != "") {
      url += `&order_field=${order}`;
      url += `&order=${typeOrder == false ? "DESC" : "ASC"}`;
    }
    if (search != "") {
      url += `&search=${search}`;
    }

    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderResume,
      true
    );
  }
  async getShareholdersNoWithAccount(): Promise<Response<ShareholderResume[]>> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/shareholders/account/without-user `;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderResume,
      true
    );
  }
  async deleteShareholder(shareholderId: number): Promise<Response<any>> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/shareholders/${shareholderId}/account`;
    return getResponse(url, this.token, "DELETE", undefined, undefined);
  }

  async getShareholderDetail(
    shareholderId: number
  ): Promise<Response<Shareholder>> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/shareholders/${shareholderId}/account`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      Shareholder
    );
  }

  async getShareholderResume(
    //asdas
    shareholderId: number
  ): Promise<Response<ShareholderResume>> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/shareholders/${shareholderId}/account`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderResume
    );
  }

  async downloadExcel(dividendId: number): Promise<void> {
    const url = `${
      getEnvConfig().apiURL
    }/dividends/payments/all/excel/?dividend_id=${dividendId}`;

    return fetchDownloadFile(url, "dividendos-por-id.xlsx");
  }

  async downloadShareholderList(): Promise<void> {
    const url = `${getEnvConfig().apiURL}/admin/user_accounts/excel`;

    return fetchDownloadFile(url, "listado_accionistas.xlsx");
  }
}
