import { ref, computed, isRef, ComputedRef } from 'vue';
import { defineStore } from "pinia";

import { DividendService } from '@/services/dividends/dividendService';
import { DividendClass, DividendType, DividendUpdateType } from '@/services/dividends/types';

import { Response } from '@/services/types';

const useDividendsStore = defineStore('dividendsStore', () => {
    const dividendService = new DividendService();

    const dividendForm = ref<DividendUpdateType>({
        code:'',
        divType:undefined,
        ufPerShare:undefined,
        totalUf:undefined,
        distributionDate: '',
        cutoffDate: '',
        groupARatio: undefined,
    });
    const updateDividendResponse = ref<Response<DividendClass>>();

    const updateDividend = async (dividendId: number) => {
        if(dividendForm.value) updateDividendResponse.value = await dividendService.editDividend(dividendId, dividendForm.value);
    }

    const setDividendForm = (_dividendData: DividendType) => {
        const dividendData: ComputedRef<DividendType>  = computed((): DividendType => isRef(_dividendData) ? _dividendData.value as DividendType : _dividendData);
        dividendForm.value.code = dividendData.value.code;
    }

    return {
        dividendForm,
        setDividendForm,
        updateDividendResponse,
        updateDividend,   
    }
})

export default useDividendsStore;