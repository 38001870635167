import { ref, watch,computed } from "vue";
import { defineStore } from "pinia";
import {
  CreateMessageBulkLoad,
  MessageType,
} from "@/services/notifications/types";
import { Response, ResponsePagination } from "@/services/types";
import { NotificationServices } from "@/services/notifications/NotificationServices";
import NewPaginationTableComponent from "@/components/molecules/NewPaginationTableComponent.vue";

export const useNotificationStore = defineStore("messageStore", () => {
  const notificationService = new NotificationServices();
  const getIndexResponse = ref<ResponsePagination<MessageType[]>>();
  const messageList = ref<MessageType[]>();

  const getMessageResponse = ref<Response<MessageType>>();
  const message = ref<MessageType>();

  const downloadFileResponse = ref<Response<any>>();

  const pagination = ref<any>({
    page: 1,
    perPage: undefined,
    totalPages: undefined,
    total_record:undefined,
  });

  // Bulk load related stuff

  const bulkLoadForm = ref<any>({
    subject: "",
    description: "",
    thumbnail: undefined,
  });

  const clearBulkloadForm = () => {
    bulkLoadForm.value.subject = '';
    bulkLoadForm.value.description = '';
    bulkLoadForm.value.thumbnail = undefined;
  }

  const createBulkLoadResponse = ref<Response<any>>();

  const getIndex = async () => {
    getIndexResponse.value = await notificationService.getIndex(
      pagination.value.page
    );

    if (getIndexResponse.value) {
      messageList.value = getIndexResponse.value?.data;
      pagination.value.page = getIndexResponse.value?.page;
      pagination.value.perPage = getIndexResponse.value?.per_page;
      pagination.value.totalPages = getIndexResponse.value?.total_pages;
    }
  };

  const getMessage = async (messageId: number) => {
    getMessageResponse.value = await notificationService.getMessage(messageId);

    if (getMessageResponse.value.success) {
      message.value = { ...getMessageResponse.value.data };
    }
  };

  const createBulkLoad = async () => {
    createBulkLoadResponse.value = await notificationService.createBulkLoad(
      bulkLoadForm.value
    );
  };

  const downloadFile = async (fileId:number, fileName: string | null) => {
    await notificationService.downloadFile(fileId, fileName);
  }

  const resetPagination = () => {
    pagination.value = {
      page: 1,
      per_page: undefined,
      total_pages: undefined,
    };
  };


  return {
    notificationService,
    getIndexResponse,

    resetPagination,
    getIndex,
    nextPage: () =>
      pagination.value.page <= pagination.value.totalPages
        ? pagination.value.page++
        : undefined,
    prevPage: () =>
      pagination.value.page > 0 ? pagination.value.page-- : undefined,
    setPage: (newPage: number) =>
      newPage > 0 && newPage <= pagination.value.totalPages
        ? (pagination.value.page = newPage)
        : undefined,

    messageList,
    pagination,

    getMessage,
    getMessageResponse,
    message,

    downloadFile,

    // bulk load stuff,
    bulkLoadForm,
    createBulkLoadResponse,
    createBulkLoad,
    clearBulkloadForm,
  };
});

export default useNotificationStore;
