
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    switchOptions: {
      type: [Array, String],
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    defaultIndexSelection: {
      type: Number,
      require: false,
      default: 0,
    },
  },
  emits: ["switchTypeSelection"],
  setup(props, { emit }) {
    const defaultOptionIndex = ref<number>(props.defaultIndexSelection | 0);
    const selectedOptionIndex = ref<number>(defaultOptionIndex.value);
    function handleSwitchingClick(option: number) {
      option == 1
        ? (selectedOptionIndex.value = 1)
        : (selectedOptionIndex.value = 0);
      console.log("hay que emitir la selection");
      emit("switchTypeSelection", selectedOptionIndex.value);
    }
    return {
      defaultOptionIndex,
      selectedOptionIndex,
      handleSwitchingClick,
    };
  },
});
