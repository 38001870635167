import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { fetchDownloadFileBodyRequired, getResponse, keysToSnake } from "../utils";
import { Response } from "../types";
import {
  NotificationSettings,
  NotificationSettingsEdit,
  Notification,
  CreateMessageBulkLoad,
} from "./types";

import { ResponsePagination } from "../types";
import { getResponsePagination } from "../utils";
import { MessagesClass, MessageType } from "./types";

export class NotificationServices extends AuthenticatedService {
  async getSettings(user_id: number): Promise<Response<NotificationSettings>> {
    const url = `${
      getEnvConfig().apiURL
    }/notifications/settings/get?user_id=${user_id}`;

    return getResponse(url, this.token);
  }

  async editSettings(
    user_id: number,
    settings: NotificationSettingsEdit
  ): Promise<Response<null>> {
    const url = `${
      getEnvConfig().apiURL
    }/notifications/settings/edit?user_id=${user_id}`;

    return getResponse(url, this.token, "POST", keysToSnake(settings));
  }

  async getNotifications(): Promise<Response<Notification[]>> {
    const url = `${getEnvConfig().apiURL}/notifications/all`;

    return getResponse(url, this.token);
  }

  async readNotification(
    notificationId: number
  ): Promise<Response<Notification>> {
    const url = `${getEnvConfig().apiURL}/notifications/read`;

    return getResponse(url, this.token, "POST", {
      notification_id: notificationId,
    });
  }

  async readAllNotifications(): Promise<Response<null>> {
    const url = `${getEnvConfig().apiURL}/notifications/read-all`;

    return getResponse(url, this.token, "POST");
  }

  async sendMessage(messageObject: any) {
    const url = `${getEnvConfig().apiURL}/admin/sending/create`;

    const formData: FormData = new FormData();

    formData.append("subject", messageObject.subject);
    formData.append("description", messageObject.description);
    formData.append("all", messageObject.all ? "1" : "0");

    messageObject.users.forEach((user: any) =>
      formData.append("sending_shareholder", user.id)
    );

    for (const file of messageObject.files) {
      formData.append("thumbnails", file);
    }

    return getResponse(
      url,
      this.token,
      "POST",
      undefined,
      formData,
      undefined,
      undefined
    );
  }

  async getIndex(page = 1): Promise<ResponsePagination<MessageType[]>> {
    const url = `${getEnvConfig().apiURL}/admin/sending?page=${page}`;
    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      MessagesClass
    );
  }

  async getMessage(messageId: number): Promise<Response<MessageType>> {
    const url = `${getEnvConfig().apiURL}/admin/sending/${messageId}`;
    return getResponse(url, this.token, "GET", undefined, undefined);
  }

  async createBulkLoad(form: CreateMessageBulkLoad): Promise<Response<any>> {
    const formData = new FormData();
    formData.append("subject", form.subject);
    formData.append("description", form.description);
    formData.append("thumbnail", form.thumbnail);

    const url = `${getEnvConfig().apiURL}/admin/sending/create/massive`;
    return getResponse(url, this.token, "POST", undefined, formData);
  }

  async downloadFile(fileId: number, fileName: string | null): Promise<any> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/sending-file/${fileId}/download`;
    return fileName
      ? fetchDownloadFileBodyRequired(url, this.token, "GET", fileName)
      : null;
  }
}
