
import InputFile from "@/components/atoms/InputFile.vue";
import NewModal from "@/components/atoms/NewModal.vue";
import { defineComponent, ref } from "vue";
import { MovementService } from "@/services/movements/MovementService";
import { UploadContractData } from "@/services/movements/types";

import Swal from "sweetalert2";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      defaul: false,
    },
    movementId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NewModal,
    InputFile,
  },
  emits: ["update:visible", "reload"],
  setup(prop, { emit }) {
    const movementService = new MovementService();
    const isLoading = ref<boolean>(false);
    async function uploadFile(file: File) {
      isLoading.value = true;
      let contractData = new UploadContractData(prop.movementId, file);
      let response = await movementService.uploadContract(contractData);
      if (!response) {
        Swal.fire("Error!", "No se pudo subir el contrato", "error");
        emit("update:visible", false);
        isLoading.value = false;
        return false;
      }
      Swal.fire("¡Listo!", "Contrato subido con exito", "success");
      emit("update:visible", false);
      emit("reload", prop.movementId);
      isLoading.value = false;
    }
    return { uploadFile, isLoading };
  },
});
