import { User } from "../auth/types"

export type NotificationType = "ADMIN_REQUEST_NEW" |
                        "ADMIN_REQUEST_APPROVED" |
                        "ADMIN_REQUEST_REJECTED" |
                        "ADMIN_DIVIDEND_CREATED" |
                        "ADMIN_MOVEMENT_CREATED" |
                        "ADMIN_MOVEMENT_CANCELLED" |
                        "USER_REQUEST_APPROVED" |
                        "USER_REQUEST_REJECTED" |
                        "USER_MOVEMENT_CREATED" |
                        "USER_MOVEMENT_CANCELLED";

export interface NotificationSettings{
    id: number
    userId: number
    receiveMails: boolean
    blockedNotifications: NotificationType[]
}

export interface NotificationSettingsEdit{
    receiveMails?: boolean
    blockedNotifications?: NotificationType[]
}

export interface Notification {
    notType: NotificationType
    userId?: number
    message: string
    id: number
    isRead: boolean
    isReadAdmin:boolean
    createdAt: string
}

export type MessageType = {
    id: string,
    subject: string,
    description: string,
    createdAt: string | Date,
    user: User,
    files: any,
    sendingShareholder: User[],
    isSendedAllShareholders: boolean,
}

export type CreateMessageBulkLoad = {
    subject: string,
    description: string,
    thumbnail: File,
}

export class MessagesClass {
    constructor(readonly messagesData: MessageType) {}
}