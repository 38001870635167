import { ref } from "vue";
import { defineStore } from "pinia";

import Swal from "sweetalert2";
import { InvestmentService } from "@/services/invest/investmentService";
import {
  CreateInvestimentContractAlert,
  InvestmentContractAlert,
} from "@/services/invest/types";
import { Response, ResponsePagination } from "@/services/types";

export const useInvestmentContractAlertsStore = defineStore(
  "investmentContractAlertsStore",
  () => {
    const investmentService = new InvestmentService();
    const alertList = ref<InvestmentContractAlert[]>();
    const alert = ref<InvestmentContractAlert>();
    const fetchAlertsResponse =
      ref<ResponsePagination<InvestmentContractAlert[]>>();
    const fetchAlertResponse = ref<Response<InvestmentContractAlert>>();

    const createResponse = ref<Response<void>>();
    const createForm = ref<CreateInvestimentContractAlert>({
      shipmentDate: new Date(),
      description: "",
    });
    const updateForm = ref<CreateInvestimentContractAlert>({
      shipmentDate: new Date(),
      description: "",
    });

    const updateResponse = ref<Response<void>>();
    const deleteResponse = ref<Response<void>>();

    const pagination = ref<any>({
      page: 1,
      per_page: undefined,
      total_pages: 1,
      //total_record:undefined,
    });

    const fetchAlerts = async (contractId: number) => {
      fetchAlertsResponse.value =
        await investmentService.getInvestmentContractAlerts(
          contractId,
          pagination.value.page
        );

      if (fetchAlertsResponse.value?.success) {
        alertList.value = fetchAlertsResponse.value?.data;
        pagination.value = {
          page: fetchAlertsResponse.value.page,
          per_page: fetchAlertsResponse.value.per_page,
          total_pages: fetchAlertsResponse.value.total_pages,
        };
      }
    };

    const fetchAlert = async (alertId: number) => {
      fetchAlertResponse.value =
        await investmentService.getInvestmentContractAlert(alertId);

      if (fetchAlertResponse.value?.success) {
        alert.value = fetchAlertResponse.value?.data;

        updateForm.value.shipmentDate =
          fetchAlertResponse.value?.data?.shipmentDate;
        updateForm.value.description =
          fetchAlertResponse.value?.data?.description;
      }
    };

    const createAlert = async (contractId: number) => {
      if (createForm.value) {
        createResponse.value =
          await investmentService.createInvestmentContractAlert(
            contractId,
            createForm.value
          );

        if (createResponse.value.success) {
          Swal.fire("¡Éxito!", "Alerta creada exitosamente.", "success");
        } else {
          Swal.fire("Error", "Oops, tuvimos un problema.", "error");
        }
        fetchAlerts(contractId);
      }
    };

    const updateAlert = async (alertId: number, contractId: number) => {
      if (updateForm.value) {
        updateResponse.value =
          await investmentService.editInvestmentContractAlert(
            alertId,
            updateForm.value
          );

        if (updateResponse.value.success) {
          Swal.fire("¡Éxito!", "Alerta actualizada exitosamente.", "success");
        } else {
          Swal.fire("Error", "Oops, tuvimos un problema.", "error");
        }
        fetchAlerts(contractId);
      }
    };

    const deleteAlert = async (alertId: number, contractId: number) => {
      deleteResponse.value =
        await investmentService.deleteInvestmentContractAlert(alertId);

      if (deleteResponse.value.success) {
        Swal.fire("¡Éxito!", "Alerta eliminada exitosamente.", "success");
      } else {
        Swal.fire("Error", "Oops, tuvimos un problema.", "error");
      }

      fetchAlerts(contractId);
    };

    return {
      alertList,
      pagination,
      fetchAlertsResponse,
      fetchAlertResponse,

      fetchAlerts,
      fetchAlert,

      createForm,
      createAlert,
      createResponse,
      clearCreateForm: () =>
        (createForm.value = {
          shipmentDate: new Date(),
          description: "",
        }),

      updateForm,
      updateAlert,
      updateResponse,
      clearUpdateForm: () =>
        (updateForm.value = {
          shipmentDate: new Date(),
          description: "",
        }),

      deleteAlert,
      deleteResponse,
    };
  }
);

export default useInvestmentContractAlertsStore;
