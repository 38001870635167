import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import ResetPassword from "@/views/ResetPassword.vue";
// import TestView from "@/views/TestView.vue";
import DividendsView from "@/views/admin/DividendsView.vue";
import DividendsViewWithId from "@/views/admin/DividendsViewWithId.vue";
import ShareholdersView from "@/views/admin/ShareholdersView.vue";
import ShareholderDetailView from "@/views/admin/ShareholderDetailView.vue";
import UserDetailView from "@/views/admin/UserDetailView.vue";
import ParamsView from "@/views/admin/ParamsView.vue";
import InvestmentsView from "@/views/admin/InvestmentsView.vue";
import UsersView from "@/views/admin/UsersView.vue";
import MessageListView from '@/views/admin/MessageListView.vue';
import MessageViewWithId from '@/views/admin/MessageViewWithId.vue';

import InvestmentContractsView from "@/views/admin/InvestmentContractsView.vue";
import InvestmentContractAlertsView from "@/views/admin/InvestmentContractAlertsView.vue";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: LoginView,
  // },
  // {
  //   path: "/reset-password/:token",
  //   name: "ResetPassword",
  //   component: ResetPassword,
  // },

  {
    path: '/',
    name: 'auth',
    component: () => import('@/views/AuthView.vue'),
    meta: { requiresAuth: false },
    redirect: { name: 'auth-login'},
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('@/components/auth/AuthLogin.vue')
      },
      {
        path: 'forgot_password',
        name: 'auth-forgot-password',
        component: () => import('@/components/auth/AuthForgotPassword.vue')
      },
      {
        path: 'reset-password/:tokenPassword',
        name: 'auth-forgot-password-return',
        component: () => import('@/components/auth/AuthForgotPasswordReturn.vue')
      }
    ]
  },
  {
    path: "/dividends",
    name: "dividends",
    meta: { requiresAuth: true },
    redirect: { name: 'dividends-view'},
    children: [
      {
        path: '',
        name: 'dividends-view',
        component: DividendsView,
      },
      {
        path: ':id',
        name: 'dividends-id',
        component: DividendsViewWithId,
      }
    ]
  },
  {
    path: "/messages",
    meta: { requiresAuth : true},
    children: [
        {
          path: "",
          name: "message-list",
          component: MessageListView,
        },
        {
          path: ":id",
          name: "message-id",
          component: MessageViewWithId,
        }
    ]
  },
  {
    path: "/shareholders",
    meta: { requiresAuth: true },
    name: "shareholders",
    component: ShareholdersView,
  },
  {
    path: "/shareholder/:id",
    name: "shareholderDetail",
    meta: { requiresAuth: true },
    component: ShareholderDetailView,
  },
  {
    path: "/usuarios",
    meta: { requiresAuth: true },
    name: "users",
    component: UsersView,
  },
  {
    path: "/usuarios/:id",
    name: "userDetail",
    meta: { requiresAuth: true },
    component: UserDetailView,
  },
  {
    path: "/params",
    name: "params",
    meta: { requiresAuth: true },
    component: ParamsView,
  },
  {
    path: "/investments",
    //name: "investments",
    meta: { requiresAuth: true },
    //component: InvestmentsView,
    children: [
      {
        path: "",
        name: "investments",
        meta: { requiresAuth: true },
        component: InvestmentsView,
      },
      {
        path: ":id/contracts",
        children: [
          {
            path: "",
            name: "investmentContracts",
            component: InvestmentContractsView,
          },
          {
            path: ":id/alerts",
            children: [
              {
                path: "",
                name: "investmentContractAlerts",
                component: InvestmentContractAlertsView,
              },
            ]
            
          }
        ]
      }
    ]
  },

  // {
  //   path: "/*",
  //   redirect: "/"
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const isAuthenticated = authStore.token !== null

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Esta ruta requiere autenticación
    if (!isAuthenticated) {
      // Si el usuario no está autenticado, redirige a la página de inicio de sesión
      next({ name: 'auth-login' });
    } else {
      // Si el usuario está autenticado, permite la navegación normal
    
      next();
    }
  } else {
    // Esta ruta no requiere autenticación
    if(isAuthenticated){
      
      next({name:"shareholders"});
    }else{
      next();
    }

    // Si el usuario no está autenticado, permite la navegación normal
    
  }
});

export default router;
