import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import {
  fetchDownloadFileBodyRequired,
  getResponse,
  keysToSnake,
} from "../utils";
import { ContractReq } from "./types";
import { reformatDateISO8601 } from "@/services/utils";
export class ContractService extends AuthenticatedService {
  async createContract(contractReq: ContractReq): Promise<any> {
    const fileName = `contrato_mov_cod_${contractReq.movementId}.pdf`;
    const url = `${getEnvConfig().apiURL}/movements/contracts/create`;
    return fetchDownloadFileBodyRequired(
      url,
      this.token,
      "POST",
      fileName,
      keysToSnake(contractReq)
    );
  }

  async getInvestmentContracts(
    investmentId: number,
    filter: object = {},
    page: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract/?investment_id=${investmentId}&page=${page}&${this.parseFilters(
      filter
    )}`;
    return getResponse(url, this.token, "get");
  }

  async getInvestmentContract(contractId: number) {
    const url = `${getEnvConfig().apiURL}/investments/contract/${contractId}`;
    return getResponse(url, this.token, "get");
  }

  async createInvestmentContract(contractData: any) {
    const url = `${getEnvConfig().apiURL}/investments/contract/create`;
    const formData = new FormData();

    formData.append("investment_id", contractData.investment_id);
    formData.append("type", contractData.type);

    formData.append(
      "start_date",
      reformatDateISO8601(contractData.start_date, true, true)
    );

    if (contractData.end_date != undefined || contractData.end_date != null) {
      formData.append(
        "end_date",
        reformatDateISO8601(contractData.end_date, true, true)
      );
    }

    formData.append("thumbnail", contractData.thumbnail);
    formData.append(
      "notify_indefinitely",
      contractData.notify_indefinitely ? "1" : "0"
    );
    formData.append("comment", contractData.comment);

    return getResponse(url, this.token, "post", undefined, formData);
  }

  async deleteInvestmentContract(contractId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract/${contractId}/delete`;
    return getResponse(url, this.token, "delete");
  }

  async downloadInvestmentContract(contractId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract/${contractId}/download`;
    return fetchDownloadFileBodyRequired(
      url,
      this.token,
      "get",
      "contrato.pdf"
    );
  }

  async updateInvestmentContract(contractId: number, contractData: any) {
    const url = `${
      getEnvConfig().apiURL
    }/investments/contract/${contractId}/update`;
    const formData = new FormData();

    formData.append("investment_id", contractData.investment_id);
    formData.append("type", contractData.type);
    formData.append(
      "start_date",
      reformatDateISO8601(contractData.start_date, true, true)
    );

    if (contractData.end_date != undefined || contractData.end_date != null) {
      formData.append(
        "end_date",
        reformatDateISO8601(contractData.end_date, true, true)
      );
    }

    if (contractData.thumbnail != "") {
      formData.append("thumbnail", contractData.thumbnail);
    }

    formData.append("comment", contractData.comment);
    formData.append(
      "notify_indefinitely",
      contractData.notify_indefinitely ? "1" : "0"
    );
    formData.append("_method", "PUT");

    return getResponse(url, this.token, "put", undefined, formData);
  }

  parseFilters(filters: any) {
    const parsedFilters = {
      start_date: "",
      end_date: "",
      search: "",
      type: "",
    };

    if (filters.start_date != "" && filters.start_date != undefined) {
      parsedFilters.start_date = reformatDateISO8601(filters.start_date);
    }

    if (filters.end_date != "" && filters.end_date != undefined) {
      parsedFilters.end_date = reformatDateISO8601(filters.end_date);
    }

    parsedFilters.type = filters.type;
    parsedFilters.search = filters.search;

    return new URLSearchParams(parsedFilters).toString();
  }
}
