import { Shareholder } from "@/services/shareholder/types";
import { defineStore } from "pinia";
import { UserService } from "@/services/user/UserService";
import { InvestmentService } from "@/services/invest/investmentService";

export const useUserStore = defineStore("user", {
  state: () => {
    let shareholder: Shareholder | undefined;
    const shareholderService = new UserService();
    const investmentService = new InvestmentService();
    let entitySettings: object | undefined;

    return {
      shareholder,
      shareholderService,
      entitySettings,
      investmentService,
    };
  },

  actions: {
    async query(id: number) {
      this.shareholderService
        .getShareholderDetail(id)
        .then(({ data }) => {
          this.shareholder = data
        });
    },
    async fetchEntitySettings(roleUser = "SHAREHOLDER", type = "Investment"){
      this.entitySettings =  await this.investmentService.fetchEntitySettings(roleUser, type); 
    }
  },

  getters: {
    getData(state) {
      return state.shareholder;
    },
    getEntitySettings(state){
      return state.entitySettings;
    }
  },
});

export type shareholderStore = ReturnType<typeof useUserStore>;
